import React from 'react';
import { GalleryAddItemDialogProps } from '../../@types/gallery/props/GalleryAddItemDialogProps';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

const GalleryAddDialog: React.FC<GalleryAddItemDialogProps> = ({
  isOpen,
  onClose,
  onAddItem,
}) => {
  const [uri, setUri] = React.useState<string>('');

  const handleClose = () => onClose();
  const handleAddItem = () => {
    onAddItem({ uri, addedAt: new Date()});
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="image-preview-dialog"
    >
      <DialogContent>
        <TextField
          autoFocus
          label="Image URI"
          variant="standard"
          onChange={(e) => setUri(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddItem} color="primary">
            Add
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default GalleryAddDialog;
