import React from 'react';

import { RoutineProps } from '../../@types/routine/props/RoutineProps';
import RoutineOutlook from './RoutineOutlook';
import RoutineExercises from './RoutineExercises';
import RoutineDays from './RoutineDays';
import { Stack } from '@mui/material';

const Routine: React.FC<RoutineProps> = ({
  exercises,
  routines,
  routineHistory,
  onExercisesChange,
  onRoutinesChange,
  onRoutineHistoryChange,
}) => {
  return (
    <div>
      <h1>Routine</h1>
      <Stack spacing={5}>
        <RoutineOutlook
          exercises={exercises}
          routines={routines}
          routineHistory={routineHistory}
          onRoutineHistoryChange={onRoutineHistoryChange}
        />

        <RoutineDays
          routines={routines}
          exercises={exercises}
          onRoutinesChange={onRoutinesChange}
        />

        <RoutineExercises
          exercises={exercises}
          routines={routines}
          onExercisesChange={onExercisesChange}
          onRoutinesChange={onRoutinesChange}
        />
      </Stack>
    </div>
  );
};

export default Routine;
