import React from 'react';
import {
  Button,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { RoutineDaysProps } from '../../@types/routine/props/RoutineDaysProps';
import { RoutineDay } from '../../@types/routine/RoutineDay';

import RoutineDaysAddDayDialog from './RoutineDaysAddDayDialog';

const RoutineDays: React.FC<RoutineDaysProps> = ({
  routines,
  exercises,
  onRoutinesChange,
}) => {
  const [openAddDialog, setOpenAddDialog] = React.useState<boolean>(false);
  const [selectedExercise, setSelectedExercise] = React.useState<string>('');

  const daysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const getDaysOfWeekWithNoRoutine = (): string[] => {
    const daysOfWeek = [...daysOfTheWeek];
    routines.forEach((routine) => {
      daysOfWeek.splice(daysOfWeek.indexOf(routine.dayOfTheWeek), 1);
    });

    return daysOfWeek;
  };

  const handleAddExerciseToRoutine = (day: string, exerciseId: string) => {
    let newItems = [...routines];
    let routineDay = newItems.find((r) => r.dayOfTheWeek === day);
    let exercise = exercises.find((e) => e.id === exerciseId);
    if (routineDay && exercise) {
      routineDay.exercises.push({ exercise, set: exercise.defaultSet });
    }

    onRoutinesChange(newItems);
  };

  const handleAddItem = (item: string) => {
    let newItems = [
      ...routines,
      { dayOfTheWeek: item, isRestDay: false, exercises: [] },
    ];

    newItems.sort((a, b) => {
      const indexA = daysOfTheWeek.indexOf(a.dayOfTheWeek);
      const indexB = daysOfTheWeek.indexOf(b.dayOfTheWeek);
      return indexA - indexB;
    });

    onRoutinesChange(newItems);
  };

  const handleDeleteRoutine = (item: RoutineDay) => {
    let newItems = routines.filter((i) => i.dayOfTheWeek !== item.dayOfTheWeek);
    onRoutinesChange(newItems);
  };

  const handleDeleteExerciseFromRoutine = (
    routine: RoutineDay,
    exerciseId: string
  ) => {
    let newItems = [...routines];
    let routineDay = newItems.find(
      (r) => r.dayOfTheWeek === routine.dayOfTheWeek
    );
    if (routineDay) {
      routineDay.exercises = routineDay.exercises.filter(
        (e) => e.exercise.id !== exerciseId
      );
    }

    onRoutinesChange(newItems);
  };

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  return (
    <div>
      <h3>
        Routine Days
        <Fab
          size="small"
          aria-label="add"
          onClick={() => handleOpenAddDialog()}
          style={{ marginLeft: 10, width: 25, height: 25, minHeight: 0 }}
        >
          <AddIcon />
        </Fab>
      </h3>

      <Paper style={{ padding: 8, paddingBottom: 18 }}>
        {routines.length === 0 && (
          <Typography
            variant="body1"
            style={{
              fontStyle: 'italic',
              textAlign: 'center',
              padding: 20,
              paddingTop: 30,
            }}
          >
            No routine days planned yet
          </Typography>
        )}
        {routines.map((routine, index) => (
          <div key={index} style={{ marginLeft: 10, marginRight: 10 }}>
            <h4>
              {routine.dayOfTheWeek}s
              <IconButton
                size="small"
                onClick={() => handleDeleteRoutine(routine)}
              >
                <DeleteIcon />
              </IconButton>
            </h4>

            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell align="right">Sets</TableCell>
                    <TableCell align="right">Reps</TableCell>
                    <TableCell align="right">Rest (mins)</TableCell>
                    <TableCell align="right">Weight (kg)</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {routine.exercises.length === 0 && (
                    <TableCell colSpan={6} component="th" scope="row">
                      <Typography
                        variant="body1"
                        style={{
                          fontStyle: 'italic',
                          textAlign: 'center',
                          padding: 20,
                        }}
                      >
                        No exercises added to this day yet
                      </Typography>
                    </TableCell>
                  )}
                  {routine.exercises.map((exercise) => (
                    <TableRow
                      key={'exercise-' + exercise.exercise.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          {exercise.exercise.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {exercise.set.setCount}
                      </TableCell>
                      <TableCell align="right">
                        {exercise.set.repCount}
                      </TableCell>
                      <TableCell align="right">
                        {exercise.set.restMinutes}
                      </TableCell>
                      <TableCell align="right">
                        {exercise.set.weightKilograms || <em>N/A</em>}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() =>
                            handleDeleteExerciseFromRoutine(
                              routine,
                              exercise.exercise.id
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              style={{ marginTop: 15 }}
            >
              <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id={'select-exercise-label-' + index}>
                  Exercise
                </InputLabel>
                <Select
                  labelId={'select-exercise-label-' + index}
                  id={'select-exercise-' + index}
                  label="Exercise"
                  value={selectedExercise}
                  onChange={(e) => setSelectedExercise(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {exercises.map((exercise) => (
                    <MenuItem key={exercise.id} value={exercise.id}>
                      {exercise.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() =>
                  handleAddExerciseToRoutine(
                    routine.dayOfTheWeek,
                    selectedExercise
                  )
                }
              >
                Add
              </Button>
            </Stack>
          </div>
        ))}
      </Paper>

      <RoutineDaysAddDayDialog
        isOpen={openAddDialog}
        availableDaysOfWeek={getDaysOfWeekWithNoRoutine()}
        onAddItem={handleAddItem}
        onClose={handleCloseAddDialog}
      />
    </div>
  );
};

export default RoutineDays;
