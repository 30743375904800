import { GalleryPreviewDialogProps } from '../../@types/gallery/props/GalleryPreviewDialogProps';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

const GalleryPreviewDialog: React.FC<GalleryPreviewDialogProps> = ({
  uri,
  title,
  isOpen,
  onClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="image-preview-dialog"
    >
      <DialogContent>
        <img
          src={uri}
          alt={title}
          style={{ width: '100%', height: 'auto' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GalleryPreviewDialog;
