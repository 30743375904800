import {
  Card,
  CardContent,
  Typography,
  Stack,
  Checkbox,
  Paper,
  Divider,
} from '@mui/material';

import RestIcon from '@mui/icons-material/KingBed';

import { RoutineDayCardProps } from '../../@types/routine/props/RoutineDayCardProps';

const RoutineDayCard: React.FC<RoutineDayCardProps> = ({
  plan,
  routineHistory,
  onCompleteExercise,
  onUnCompleteExercise,
}) => {
  const handleCompleteEntireDay = () => {
    plan.routine.exercises.forEach((exercise) => {
      onCompleteExercise(plan.date, exercise.exercise.id, exercise.set);
    });
  };

  const getIsExerciseCompleted = (exerciseId: string) => {
    const key = plan.date.toISOString().split('T')[0];

    if (routineHistory?.exerciseHistory?.hasOwnProperty(key)) {
      const exerciseHistory = routineHistory.exerciseHistory[key];
      return exerciseHistory.some((entry) => entry.exerciseId === exerciseId);
    }

    return false;
  };

  return (
    <Card key={plan.date.toISOString()} sx={{ minWidth: 350, margin: 1 }}>
      <CardContent style={{ padding: 15 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ marginBottom: 25 }}
        >
          <div>
            <Typography
              variant="h5"
              style={{
                fontWeight: 'bold',
                textDecoration: plan.isCompleted ? 'line-through' : 'none',
              }}
            >
              {plan.routine.dayOfTheWeek}
            </Typography>
            <Typography
              variant="body2"
              style={{
                padding: 0,
                textDecoration: plan.isCompleted ? 'line-through' : 'none',
              }}
            >
              {plan.date.toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
              })}{' '}
              {plan.isToday ? (
                <span style={{ fontWeight: 'bold' }}>(today)</span>
              ) : (
                ''
              )}
            </Typography>
          </div>
          <Checkbox
            checked={plan.isCompleted}
            disabled={plan.routine.isRestDay}
            onClick={handleCompleteEntireDay}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
          />
        </Stack>

        <div style={{ marginTop: 25 }}>
          {plan.routine.isRestDay && (
            <Stack
              alignItems="center"
              style={{ marginTop: 12, marginBottom: 12 }}
            >
              <RestIcon fontSize="large" />
              <Typography variant="h5">Rest day</Typography>
            </Stack>
          )}

          {!plan.routine.isRestDay && (
            <>
              <Typography variant="h6">Exercises</Typography>
              <Paper>
                {plan.routine.exercises.map((exercise) => (
                  <div key={exercise.exercise.id}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2">
                        {exercise.exercise.title}
                      </Typography>

                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography variant="body2">
                          {exercise.set.setCount} sets of{' '}
                          {exercise.set.repCount} reps
                        </Typography>
                        <Checkbox
                          size="small"
                          checked={getIsExerciseCompleted(exercise.exercise.id)}
                          disabled={plan.isCompleted}
                          onClick={() => {
                            if (getIsExerciseCompleted(exercise.exercise.id)) {
                              onUnCompleteExercise(
                                plan.date,
                                exercise.exercise.id,
                                exercise.set
                              );
                            } else {
                              onCompleteExercise(
                                plan.date,
                                exercise.exercise.id,
                                exercise.set
                              );
                            }
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Divider />
                  </div>
                ))}
              </Paper>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default RoutineDayCard;
