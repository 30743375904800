import React from 'react';
import { Masonry } from '@mui/lab';
import { Fab, IconButton, Stack, Paper, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { GalleryItem } from '../../@types/gallery/GalleryItem';
import { GalleryProps } from '../../@types/gallery/props/GalleryProps';

import GalleryAddDialog from './GalleryAddDialog';
import GalleryPreviewDialog from './GalleryPreviewDialog';

const Gallery: React.FC<GalleryProps> = ({ items, onGalleryChange }) => {
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [previewImageUri, setPreviewImageUri] = React.useState<string>('');
  const [openAddDialog, setOpenAddDialog] = React.useState<boolean>(false);

  const handleOpenPreview = (imageUri: string) => {
    setOpenPreview(true);
    setPreviewImageUri(imageUri);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setPreviewImageUri('');
  };

  const handleAddItem = (item: GalleryItem) => {
    let newItems = [...items, item];
    onGalleryChange(newItems);
  };

  const handleDeleteItem = (item: GalleryItem) => {
    let newItems = items.filter((i) => i.uri !== item.uri);
    onGalleryChange(newItems);
  };

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleAddAllDefaultItems = () => {
    let newItems = items;
    for (const uri of defaultImages) {
      newItems.push({ uri, addedAt: new Date() });
    }
    onGalleryChange(newItems);
  };

  function timeSince(date: Date): string {
    const seconds = Math.floor((new Date(date).getTime() - Date.now()) / 1000);

    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 },
    ];

    for (const interval of intervals) {
      if (seconds >= interval.seconds) {
        const count = Math.floor(seconds / interval.seconds);
        return `${count} ${interval.label}${count > 1 ? 's' : ''} ago`;
      }
    }

    return 'just now';
  }

  const defaultImages = [
    'https://files.mastodon.social/media_attachments/files/111/902/967/784/250/048/original/298342b130b9b904.jpg',
    'https://files.mastodon.social/media_attachments/files/111/900/912/800/408/728/original/82a525bbac6ad8b2.jpg',
    'https://files.mastodon.social/media_attachments/files/111/900/004/905/624/399/original/ebd7ef4178472694.jpg',
    'https://files.mastodon.social/media_attachments/files/111/900/002/921/571/112/original/4c24fc5adf436804.jpg',
    'https://files.mastodon.social/media_attachments/files/111/896/217/561/775/487/original/39f37b16ccab249e.jpg',
    'https://files.mastodon.social/media_attachments/files/111/894/374/288/748/660/original/753b43382bd71f76.jpg',
    'https://files.mastodon.social/media_attachments/files/111/902/970/371/399/559/original/7a33203e1dc7b878.jpg',
    'https://files.mastodon.social/media_attachments/files/111/861/616/679/226/488/original/6550ef3a3f86cb11.jpg',
    'https://files.mastodon.social/media_attachments/files/111/824/008/849/169/888/original/90885c4abf86252c.jpg',
    'https://files.mastodon.social/media_attachments/files/111/784/742/736/998/249/original/4b3bb045ef797743.jpg',
  ];

  return (
    <div>
      <h1>
        Inspiration Gallery
        <Fab
          size="small"
          aria-label="add"
          onClick={() => handleOpenAddDialog()}
          style={{ marginLeft: 10 }}
        >
          <AddIcon />
        </Fab>
      </h1>

      {items.length === 0 && (
        <p>
          Your inspiration gallery is lacking beef. <br />
          <Button variant="text" onClick={handleAddAllDefaultItems}>
            Add some good lookin' dudes
          </Button>
        </p>
      )}

      <Masonry columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} spacing={2}>
        {items.map((item) => (
          <div key={item.uri}>
            <img
              src={item.uri}
              alt="inspiration gallery item"
              loading="lazy"
              style={{ display: 'block', width: '100%' }}
              onClick={() => handleOpenPreview(item.uri)}
            />
            <Paper style={{ borderRadius: 0 }}>
              <Stack direction="row" justifyContent="space-between">
                <p style={{ margin: 0, paddingLeft: 8, lineHeight: 1.4 }}>
                  {timeSince(item.addedAt)}
                </p>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => handleDeleteItem(item)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Paper>
          </div>
        ))}
      </Masonry>

      <GalleryPreviewDialog
        uri={previewImageUri}
        isOpen={openPreview}
        onClose={handleClosePreview}
      />

      <GalleryAddDialog
        isOpen={openAddDialog}
        onClose={handleCloseAddDialog}
        onAddItem={handleAddItem}
      />
    </div>
  );
};

export default Gallery;
