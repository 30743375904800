import React from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import { RoutineAddExerciseProps } from '../../@types/routine/props/RoutineAddExerciseProps';

const RoutineAddExerciseDialog: React.FC<RoutineAddExerciseProps> = ({
  isOpen,
  onClose,
  onAddItem,
}) => {
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [setCount, setSetCount] = React.useState<number>(0);
  const [repCount, setRepCount] = React.useState<number>(0);
  const [restMinutes, setRestMinutes] = React.useState<number>(0);
  const [weightKilograms, setWeightKilograms] = React.useState<number>(0);

  const handleClose = () => onClose();
  const handleAddItem = () => {
    onAddItem({
      id: Math.random().toString(36).substring(7),
      title: title,
      description: description,
      defaultSet: {
        setCount: setCount,
        repCount: repCount,
        restMinutes: restMinutes,
        weightKilograms: weightKilograms === 0 ? undefined : weightKilograms
      },
    });
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        sx={{
          '& .MuiTextField-root': { m: 2, display: 'block' },
        }}
      >
        <TextField
          autoFocus
          label="Title"
          variant="standard"
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="Description"
          variant="standard"
          rows={5}
          onChange={(e) => setDescription(e.target.value)}
          multiline
        />

        <Divider />

        <TextField
          label="Sets"
          type="number"
          variant="standard"
          onChange={(e) => setSetCount(parseInt(e.target.value))}
        />
        <TextField
          label="Reps"
          type="number"
          variant="standard"
          onChange={(e) => setRepCount(parseInt(e.target.value))}
        />
        <TextField
          label="Rest (mins)"
          type="number"
          variant="standard"
          onChange={(e) => setRestMinutes(parseInt(e.target.value))}
        />
        <TextField
          label="Weight (kg, optional)"
          type="number"
          variant="standard"
          onChange={(e) => setWeightKilograms(parseInt(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddItem} color="primary">
            Add
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RoutineAddExerciseDialog;
