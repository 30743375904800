import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
} from '@mui/material';

import { RoutineDaysAddDayDialogProps } from '../../@types/routine/props/RoutineDaysAddDayDialog';

const RoutineDaysAddDayDialog: React.FC<RoutineDaysAddDayDialogProps> = ({
  isOpen,
  availableDaysOfWeek,
  onAddItem,
  onClose,
}) => {
  const [day, setDay] = React.useState<string>(availableDaysOfWeek[0] || '');

  const handleClose = () => onClose();
  const handleAddItem = () => {
    onAddItem(day);
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        sx={{
          '& .MuiTextField-root': { m: 2, display: 'block' },
        }}
      >
        <Select value={day} onChange={(e) => setDay(e.target.value as string)}>
          {availableDaysOfWeek.map((d) => (
            <MenuItem key={d} value={d}>{d}</MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddItem} color="primary">
            Add
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RoutineDaysAddDayDialog;
