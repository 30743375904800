import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  IconButton,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { RoutineExercisesProps } from '../../@types/routine/props/RoutineExercisesProps';
import { Exercise } from '../../@types/routine/Exercise';

import RoutineAddExerciseDialog from './RoutineAddExerciseDialog';

const RoutineExercises: React.FC<RoutineExercisesProps> = ({
  exercises,
  routines,
  onExercisesChange,
  onRoutinesChange,
}) => {
  const [openAddDialog, setOpenAddDialog] = React.useState<boolean>(false);

  const handleAddItem = (item: Exercise) => {
    let newItems = [...exercises, item];
    onExercisesChange(newItems);
  };

  const handleDeleteItem = (item: Exercise) => {
    let newExercises = exercises.filter((i) => i.id !== item.id);
    onExercisesChange(newExercises);

    // Remove exercise from routines
    let newRoutines = [...routines];
    newRoutines.forEach((routine) => {
      routine.exercises = routine.exercises.filter(
        (e) => e.exercise.id !== item.id
      );
    });
    onRoutinesChange(newRoutines);
  };

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  return (
    <div>
      <h3>
        Exercises
        <Fab
          size="small"
          aria-label="add"
          onClick={() => handleOpenAddDialog()}
          style={{ marginLeft: 10, width: 25, height: 25, minHeight: 0 }}
        >
          <AddIcon />
        </Fab>
      </h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Details</TableCell>
              <TableCell colSpan={5}>Default set</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Sets</TableCell>
              <TableCell>Reps</TableCell>
              <TableCell>Rest (mins)</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {exercises.map((exercise) => (
              <TableRow
                key={exercise.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {' '}
                  {exercise.title}{' '}
                </TableCell>
                <TableCell>{exercise.description}</TableCell>
                <TableCell>{exercise.defaultSet?.setCount}</TableCell>
                <TableCell>{exercise.defaultSet?.repCount}</TableCell>
                <TableCell>{exercise.defaultSet?.restMinutes}</TableCell>
                <TableCell>{exercise.defaultSet?.weightKilograms}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeleteItem(exercise)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RoutineAddExerciseDialog
        isOpen={openAddDialog}
        onClose={handleCloseAddDialog}
        onAddItem={handleAddItem}
      />
    </div>
  );
};

export default RoutineExercises;
