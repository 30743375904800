import React from 'react';
import EditorKit, { EditorKitDelegate } from '@standardnotes/editor-kit';
import { Box, Tab, Tabs } from '@mui/material';

import { State } from '../@types/State';
import { GalleryItem } from '../@types/gallery/GalleryItem';
import { Exercise } from '../@types/routine/Exercise';
import { RoutineDay } from '../@types/routine/RoutineDay';
import { RoutineHistory } from '../@types/routine/RoutineHistory';

import Gallery from './gallery/Gallery';
import Routine from './routine/Routine';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default class Editor extends React.Component<{}, State> {
  private editorKit?: EditorKit;

  componentDidMount() {
    this.configureEditorKit();
  }

  configureEditorKit = () => {
    const delegate: EditorKitDelegate = {
      /** This loads every time a different note is loaded */
      setEditorRawText: (text: string) => {
        if (text.length > 0) {
          super.setState(JSON.parse(text) as State);
        } else {
          super.setState({} as State);
        }
      },
      clearUndoHistory: () => {},
      getElementsBySelector: () => [],
    };

    this.editorKit = new EditorKit(delegate, {
      mode: 'json',
      supportsFileSafe: false,
    });
  };

  setState = (state: (prevState: State) => State) => {
    super.setState(state, () => {
      try {
        this.editorKit?.onEditorValueChanged(JSON.stringify(this.state));
      } catch (error) {
        console.error('Error saving note:', error);
      }
    });
  };

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState((prevState) => ({
      ...prevState,
      activeTab: newValue,
    }));
  };

  onGalleryStateChange = (gallery: GalleryItem[]) => {
    this.setState((prevState) => ({
      ...prevState,
      gallery: gallery.sort(
        (a, b) => new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime()
      ),
    }));
  };

  onExercisesChange = (exercises: Exercise[]) => {
    this.setState((prevState) => ({
      ...prevState,
      exercises,
    }));
  };

  onRoutinesChange = (routines: RoutineDay[]) => {
    this.setState((prevState) => ({
      ...prevState,
      routines,
    }));
  };

  onRoutineHistoryChange = (routineHistory: RoutineHistory) => {
    this.setState((prevState) => ({
      ...prevState,
      routineHistory,
    }));
  };

  render(): React.ReactNode {
    return (
      <div id="sn-component" className="sn-component" tabIndex={0}>
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={this.state?.activeTab || 0}
            onChange={this.handleTabChange}
            centered
          >
            <Tab label="Routine" />
            <Tab label="Meal plan" />
            <Tab label="Progress" />
            <Tab label="Inspiration" />
          </Tabs>
        </Box>
        <CustomTabPanel value={this.state?.activeTab || 0} index={0}>
          <Routine
            exercises={this.state?.exercises || []}
            routines={this.state?.routines || []}
            routineHistory={this.state?.routineHistory}
            onExercisesChange={this.onExercisesChange}
            onRoutinesChange={this.onRoutinesChange}
            onRoutineHistoryChange={this.onRoutineHistoryChange}
          />
        </CustomTabPanel>
        <CustomTabPanel value={this.state?.activeTab || 0} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={this.state?.activeTab || 0} index={2}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel value={this.state?.activeTab || 0} index={3}>
          <Gallery
            items={this.state?.gallery || []}
            onGalleryChange={this.onGalleryStateChange}
          />
        </CustomTabPanel>
      </div>
    );
  }
}
