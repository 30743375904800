import React from 'react';

import { RoutineOutlookProps } from '../../@types/routine/props/RoutineOutlookProps';
import { RoutineDayPlan } from '../../@types/routine/RoutineDayPlan';

import RoutineDayCard from './RoutineDayCard';
import { ExerciseSet } from '../../@types/routine/ExerciseSet';

const RoutineOutlook: React.FC<RoutineOutlookProps> = ({
  exercises,
  routines,
  routineHistory,
  onRoutineHistoryChange,
}) => {
  const todaysCardRef = React.useRef<HTMLDivElement>(null);

  const daysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const getRoutinePlanDays = (): Date[] => {
    const today = new Date();
    const days = [];

    // Get the previous two days
    const previousTwoDays = new Date(today);
    for (let i = 0; i < 2; i++) {
      previousTwoDays.setDate(previousTwoDays.getDate() - 1);
      days.push(new Date(previousTwoDays));
    }

    // Get today
    days.push(today);

    // Get the next five days
    const nextFiveDays = new Date(today);
    for (let i = 0; i < 5; i++) {
      nextFiveDays.setDate(nextFiveDays.getDate() + 1);
      days.push(new Date(nextFiveDays));
    }

    return days;
  };

  const getNextWeekRoutine = (): RoutineDayPlan[] => {
    const days = getRoutinePlanDays();
    const nextWeekRoutine: RoutineDayPlan[] = [];

    days.forEach((day) => {
      const routineDay = routines.find(
        (routine) => routine.dayOfTheWeek === daysOfTheWeek[day.getDay()]
      );

      let isToday = day.toDateString() === new Date().toDateString();

      if (routineDay) {
        // There is a routine planned for this day
        let isCompleted = false;
        let historyKey = day.toISOString().split('T')[0];
        if (routineHistory?.exerciseHistory?.hasOwnProperty(historyKey)) {
          isCompleted =
            routineHistory?.exerciseHistory[historyKey].length ===
            routineDay.exercises.length;
        }

        nextWeekRoutine.push({
          date: day,
          isCompleted,
          isToday,
          routine: routineDay,
        });
      } else {
        // There is no routine planned for this day, it's a rest day
        nextWeekRoutine.push({
          date: day,
          isCompleted: day <= new Date() ? true : false,
          isToday,
          routine: {
            dayOfTheWeek: daysOfTheWeek[day.getDay()],
            isRestDay: true,
            exercises: [],
          },
        });
      }
    });

    return nextWeekRoutine;
  };

  const handleCompleteExercise = (
    date: Date,
    exerciseId: string,
    set: ExerciseSet
  ) => {
    const historyKey = date.toISOString().split('T')[0];
    const newRoutineHistory = { ...routineHistory };

    // Initialize the history object if it doesn't exist
    if (!newRoutineHistory.exerciseHistory)
      newRoutineHistory.exerciseHistory = {};
    if (!newRoutineHistory.totalExerciseRepCount)
      newRoutineHistory.totalExerciseRepCount = {};

    if (!newRoutineHistory.exerciseHistory?.hasOwnProperty(historyKey)) {
      newRoutineHistory.exerciseHistory[historyKey] = [];
    }

    newRoutineHistory.exerciseHistory[historyKey].push({ exerciseId, set });

    // Update the historical totals
    newRoutineHistory.totalRepsAllTime =
      (newRoutineHistory.totalRepsAllTime || 0) + set.repCount * set.setCount;

    newRoutineHistory.totalWeightKilogramsReppedAllTime =
      (newRoutineHistory.totalWeightKilogramsReppedAllTime || 0) +
      set.repCount * set.setCount * (set.weightKilograms || 0);

    newRoutineHistory.totalExerciseRepCount[exerciseId] =
      (newRoutineHistory.totalExerciseRepCount[exerciseId] || 0) +
      set.repCount * set.setCount;

    newRoutineHistory.totalExercisesPerformed =
      (newRoutineHistory.totalExercisesPerformed || 0) + 1;

    onRoutineHistoryChange(newRoutineHistory);
  };

  const handleUnCompleteExercise = (
    date: Date,
    exerciseId: string,
    set: ExerciseSet
  ) => {
    const historyKey = date.toISOString().split('T')[0];
    const newRoutineHistory = { ...routineHistory };

    // Initialize the history object if it doesn't exist
    if (!newRoutineHistory.exerciseHistory)
      newRoutineHistory.exerciseHistory = {};
    if (!newRoutineHistory.totalExerciseRepCount)
      newRoutineHistory.totalExerciseRepCount = {};

    if (!newRoutineHistory.exerciseHistory?.hasOwnProperty(historyKey)) {
      newRoutineHistory.exerciseHistory[historyKey] = [];
    }

    newRoutineHistory.exerciseHistory[historyKey] =
      newRoutineHistory.exerciseHistory[historyKey].filter(
        (entry) => entry.exerciseId !== exerciseId
      );

    // Update the historical totals
    newRoutineHistory.totalRepsAllTime =
      (newRoutineHistory.totalRepsAllTime || 0) - set.repCount * set.setCount;

    newRoutineHistory.totalWeightKilogramsReppedAllTime =
      (newRoutineHistory.totalWeightKilogramsReppedAllTime || 0) -
      set.repCount * set.setCount * (set.weightKilograms || 0);

    newRoutineHistory.totalExerciseRepCount[exerciseId] =
      (newRoutineHistory.totalExerciseRepCount[exerciseId] || 0) -
      set.repCount * set.setCount;

    newRoutineHistory.totalExercisesPerformed =
      (newRoutineHistory.totalExercisesPerformed || 0) - 1;

    onRoutineHistoryChange(newRoutineHistory);
  };

  React.useEffect(() => {
    // Scroll to today's card
    if (todaysCardRef.current) {
      todaysCardRef.current.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, []);

  return (
    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
      {getNextWeekRoutine().map((routine) => (
        <div
          key={routine.date.toISOString()}
          ref={routine.isToday ? todaysCardRef : null}
          style={{ display: 'inline-block' }}
        >
          <RoutineDayCard
            plan={routine}
            routineHistory={routineHistory}
            onCompleteExercise={handleCompleteExercise}
            onUnCompleteExercise={handleUnCompleteExercise}
          />
        </div>
      ))}
    </div>
  );
};

export default RoutineOutlook;
